<template>
  <!-- 添加编辑代理配置页面 -->
  <div>
    <p class="title"><span></span>基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-model-item label="代理等级" prop="agentName">
        <a-input v-model="form.agentName" placeholder="请输入代理等级名称" />
      </a-form-model-item>
      <a-form-model-item label="代理类型" prop="proxyType">
        <a-radio-group
          name="radioGroup"
          :value="form.proxyType"
          @change="proxyTypeChange"
        >
          <a-radio :value="1"> 按区域 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="选择区域" prop="regionType">
        <a-select
          show-search
          :disabled="$route.params.id != 0"
          placeholder="点击选择主讲老师"
          v-model="form.regionType"
          option-filter-prop="children"
          style="width: 200px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="regionTypeChange"
        >
          <a-select-option
            v-for="item in regionTypeArr"
            :value="item.labelNo"
            :key="item.labelNo"
          >
            {{ item.labelText }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="佣金类型" prop="proxyType">
        <a-radio-group
          name="radioGroup"
          :value="form.commissionType"
          @change="commissionTypeChange"
        >
          <a-radio :value="1"> 每单固定金额 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="佣金金额" prop="commission">
        <a-input-number
          v-model="form.commission"
          maxLength="5"
          :min="0"
          :max="99999.99"
          :precision="2"
          placeholder="请输入价格"
          style="width: 120px"
        />
        元
      </a-form-model-item>
      <a-form-model-item label="说明" prop="remark">
        <a-textarea v-model="form.remark" :rows="5" :maxLength="300" />
        <span class="form_span">{{ form.remark.length }}/300</span>
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="backClick">返回</a-button>
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import {
  ProxyConfigDetailApi,
  AgentConfigsUpdateApi,
} from "@/request/api/marketingCenter";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    this.form.id = this.$route.params.id;
    if (this.form.id != "0") {
      this.ProxyConfigDetailFn(this.form.id);
    }
  },
  data() {
    return {
      keepLoading: false, // 保存的loading图
      regionTypeArr: [
        { labelNo: 1, labelText: "省" },
        { labelNo: 2, labelText: "市" },
        { labelNo: 3, labelText: "区" },
      ], // 区域数组
      form: {
        id: "",
        agentName: "",
        remark: "",
        proxyType: 1,
        commissionType: 1,
        regionType: 1,
        commission: "",
      },
      rules: {
        agentName: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
        ],
        proxyType: [{ required: true }],
        commissionType: [{ required: true }],
        commission: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 代理类型改变的回调
    proxyTypeChange(e) {
      this.form.proxyType = e.target.value;
    },
    // 区域下拉选择框选中的回调
    regionTypeChange(value) {
      this.regionType = value;
      console.log(this.regionType);
    },
    // 佣金类型改变的回调
    commissionTypeChange(e) {
      this.form.commissionType = e.target.value;
    },
    // 返回的按钮
    backClick() {
      this.$router.go(-1);
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          AgentConfigsUpdateApi(this.form).then(({ code, data }) => {
            if (code == 200) {
              if (this.$route.params.id != 0) {
                message.success("编辑成功");
              } else {
                message.success("添加成功");
              }
              localStorage.setItem("proxyActiveKey", "2");
              this.$router.push("/marketingCenter/proxyManage");
            }
          });
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
    // 获取代理配置详情函数
    ProxyConfigDetailFn(id) {
      ProxyConfigDetailApi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
          this.form.proxyType = 1;
          this.form.commissionType = 1;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-input {
  resize: none;
}
</style>